import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import gainIcon from "../../assets/images/gain.png";
import lossIcon from "../../assets/images/loss.png";
import "./progressBar.css";

import { useCurrency } from "../context/CurrencyContext";

const API_BASE = "https://coincurd.com";

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  const [average, setAverage] = useState(0);

  const [lowPrice, setLowPrice] = useState(0);

  const [highPrice, setHighPrice] = useState(0);

  const { coinId } = useParams();

  const { selectedCurrency, selectedCurrencyRate } = useCurrency();

  const fetchData = async () => {
    try {
      const response = await axios.get(API_BASE + `/api/oneDay/${coinId}`);

      const result = response.data.data;

      if (response && result) {
        const sortedData = result.sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        );
        const prices = sortedData.map((item) => parseFloat(item.priceUsd));
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        setLowPrice(minPrice);

        setHighPrice(maxPrice);

        const currentPrice = parseFloat(
          sortedData[sortedData.length - 1].priceUsd
        );

        const progressPercent =
          ((currentPrice - minPrice) / (maxPrice - minPrice)) * 100;

        setProgress(progressPercent);

        const averagePrice =
          prices.reduce((sum, price) => sum + price, 0) / prices.length;

        setAverage(averagePrice);
      }
    } catch (error) {
      console.error("Waiting for server response!");
    }
  };

  useEffect(() => {
    fetchData();
  }, [coinId]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ float: "left" }}>
          <span className="text-secondary fw-bold">Low:</span>
          {/* <b className="text-dark small ps-1">${Number(lowPrice).toFixed(4)}</b> */}

          <b className="text-dark small ps-1">
            {selectedCurrency.type === "crypto"
              ? `${
                  (Number(lowPrice) / selectedCurrencyRate).toFixed(6) +
                  " " +
                  selectedCurrency.symbol
                }`
              : `${selectedCurrency.currencySymbol}${(
                  Number(lowPrice) / selectedCurrencyRate
                ).toFixed(6)}`}
          </b>
        </span>
        <span style={{ float: "right" }}>
          <span className="text-secondary fw-bold">High:</span>
          {/* <b className="text-dark small ps-1">
            ${Number(highPrice).toFixed(4)}
          </b> */}

          <b className="text-dark small ps-1">
            {selectedCurrency.type === "crypto"
              ? `${
                  (Number(highPrice) / selectedCurrencyRate).toFixed(6) +
                  " " +
                  selectedCurrency.symbol
                }`
              : `${selectedCurrency.currencySymbol}${(
                  Number(highPrice) / selectedCurrencyRate
                ).toFixed(6)}`}
          </b>
        </span>
      </div>
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progress}%`, backgroundColor: "#A6B0C3" }}
        ></div>
        <div className="triangle"></div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {progress.toFixed(2) > 0 ? (
          <div
            className="fw-bold"
            style={{
              float: "left",
              backgroundColor: "#DAFFED",
              color: "#00DFAA",
              borderRadius: "5px",
              padding: "4px",
              fontSize: "12px",
            }}
          >
            <img
              src={gainIcon}
              style={{ width: "10px", color: "#00DFAA" }}
              alt=""
            />
            &nbsp;
            {`${progress.toFixed(2)}%`}
          </div>
        ) : (
          <div
            style={{
              float: "left",
              backgroundColor: "##f55d5d",
              color: "#EA3943",
              borderRadius: "5px",
              padding: "4px",
              fontSize: "12px",
            }}
          >
            <img
              src={lossIcon}
              style={{ width: "10px", color: "#EA3943" }}
              alt=""
            />
            &nbsp;
            <span className="text-danger small">{`${progress.toFixed(
              2
            )}%`}</span>
          </div>
        )}
        <span style={{ float: "center" }}>
          <span className="text-secondary fw-bold">Avg:</span>
          {/* <b className="text-dark small ps-1">${Number(average).toFixed(4)}</b> */}

          <b className="text-dark small ps-1">
            {selectedCurrency.type === "crypto"
              ? `${
                  (Number(average) / selectedCurrencyRate).toFixed(6) +
                  " " +
                  selectedCurrency.symbol
                }`
              : `${selectedCurrency.currencySymbol}${(
                  Number(average) / selectedCurrencyRate
                ).toFixed(6)}`}
          </b>
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./cardBody.css";
const { getRecentlyAddedToken } = require("../apiHandler");

const RecentlyAddedToken = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  async function recentlyAddedToken() {
    try {
      const response = await getRecentlyAddedToken();
      let result = response.data;

      const top3Added = result.slice(0, 3);

      setData(top3Added);
      setError(null);
    } catch (error) {
      setError("Waiting for server response!");
    }
  }

  useEffect(() => {
    recentlyAddedToken();
  }, []);

  const handleRowClick = (coinId) => {
    navigate(`/currencies/${coinId}`);
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card rounded-8">
            <div className="card-body rounded-8">
              {error ? (
                <p className="text-primary text-center">{error}</p>
              ) : data.length === 0 ? (
                <p>Loading...</p>
              ) : (
                <div className="table-responsive pt-2">
                  <table className="table table-borderless">
                    <thead className="text-start">
                      <tr className="fw-bold">
                        <th className="pb-4">#</th>
                        <th className="pb-4">Recently Added</th>
                        <th className="text-end pb-4">
                          <Link
                            to="/trading_data/rankings"
                            className="text-primary"
                          >
                            more&nbsp;
                            <i className="text-link fa fa-arrow-right"></i>
                          </Link>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-start">
                      {data.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(item.coinId)}
                          className="table-hover"
                        >
                          <td className="pb-3 fw-bold">{index + 1}</td>
                          <td>
                            <img
                              style={{ width: "15px" }}
                              src={`/coinImg/${item.symbol.toLowerCase()}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/coinImg/sample.png";
                              }}
                              alt=""
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {item.coinName}&nbsp;
                            </span>
                            <span style={{ color: "#808A9D" }}>
                              {item.symbol}
                            </span>
                          </td>
                          <td className="text-end fw-bold">
                            ${Number(item.priceUsd).toFixed(4)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentlyAddedToken;

// dashbaord
import Default from "../Components";

import CoinChartMarket from "../Components/marketData/coinChartMarket";
import WatchList from "../Components/watchList";

import ExchangePair from "../Components/exchangeData/exchangeIdPair";

import Exchange from "../Components/exchangeData/exchanges";
import { TradingData } from "../Components/trading_data";
import Converter from "../Components/product/rateConversion";

export const routes = [
  { path: `/`, Component: <Default /> },

  { path: `/currencies/:coinId`, Component: <CoinChartMarket /> },

  { path: `/watchList`, Component: <WatchList /> },

  { path: `/converter`, Component: <Converter /> },

  { path: `/exchanges/:exchangeId`, Component: <ExchangePair /> },

  { path: `/rankings/exchanges`, Component: <Exchange /> },

  { path: `/trading_data/rankings`, Component: <TradingData /> },
];

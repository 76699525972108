import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import axios from "axios";
import banner4 from "../../assets/images/Up Banner ban-01.jpg";
import banner5 from "../../assets/images/Up Banner ban-02.jpg";

const API_BASE = "https://coincurd.com";

const ExchangePair = () => {
  const [exPair, setExPair] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { exchangeId } = useParams();
  const navigate = useNavigate();

  const fetchExchangePair = async () => {
    try {
      const response = await axios.get(
        `${API_BASE}/api/recentExchangePair/${exchangeId}`
      );
      const result = response.data.data;
      const dataWithSerial = result.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      setExPair(dataWithSerial);
      setLoading(false);
    } catch (error) {
      setError("Waiting for server response!");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExchangePair();
  }, [exchangeId]);

  const tableColumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "60px",
    },
    {
      name: "Currency",
      selector: (row) => row.baseId,
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        cursor: "pointer",
      },
      width: "110px",
    },
    {
      name: "Pair",
      selector: (row) =>
        row.baseSymbol && row.quoteSymbol
          ? row.baseSymbol + "/" + row.quoteSymbol
          : "",
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        cursor: "pointer",
      },
      width: "150px",
    },

    {
      name: "Price",
      selector: (row) => Number(row.priceUsd).toFixed(4),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "100px",
    },
    {
      name: "Volume",
      selector: (row) => Number(row.volumeUsd24Hr).toFixed(4),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "120px",
    },
    {
      name: "Volume%",
      selector: (row) => Number(row.volumePercent).toFixed(4),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "100px",
    },
    {
      name: "Updated",
      selector: (row) => (
        <div>
          {new Date(row.updatedAt).toLocaleString("en-IN", {
            hour12: "true",
          })}
        </div>
      ),
      sortable: true,
      center: true,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/currencies/${row.baseId}`);
  };

  return (
    <div>
      <div className="ps-2 pb-4">
        <h5 className="fw-bold text-start pt-4 ps-1">{exchangeId} Market</h5>
      </div>
      {loading ? (
        <p className="text-center text-primary">Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Row>
          <Col xl="8" className="ps-3">
            <div className="datatable-container">
              {/* <p className="fw-bold text-start bg-white p-2 fs-5">
                {exchangeId} Market
              </p> */}
              <DataTable
                data={exPair}
                columns={tableColumns}
                pagination={true}
                paginationPerPage={25}
                onRowClicked={handleRowClick}
              />
            </div>
          </Col>
          <Col xl="4" className="p-3 pt-4 d-none d-xl-block">
            <div className="text-start pt-3">
              <img
                src={banner4}
                style={{
                  width: "100%",
                  height: "600px",
                  borderRadius: "8px",
                }}
                alt="Banner 4"
              />
            </div>
            <div className="text-start mt-4 pt-4">
              <img
                src={banner5}
                style={{
                  width: "100%",
                  height: "600px",
                  borderRadius: "8px",
                }}
                alt="Banner 5"
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ExchangePair;

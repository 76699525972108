import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import "./TradingData.css"; // Import the CSS file for custom styles
import { Link } from "react-router-dom";

import { useCurrency } from "../context/CurrencyContext";

const {
  getRecentlyAddedToken,
  getTopGainers,
  getTopLoosers,
  getTopTraded,
  getLowVolume,
  getTrending,
} = require("../apiHandler");

const TradingTable = ({ data }) => {
  const { selectedCurrency, selectedCurrencyRate } = useCurrency();

  return (
    <div className="table-responsive">
      {data.length > 0 ? (
        <table className="table table-borderless text-start trading-table">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>#</th>
              <th style={{ width: "45%" }}>Name</th>
              <th style={{ width: "25%" }}>Price</th>
              <th style={{ width: "10%" }}>24Hr%</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item._id}>
                <td>{index + 1}</td>
                <td>
                  <Link to={`/currencies/${item.coinId}`}>
                    <img
                      className="coin-icon"
                      src={`/coinImg/${item.symbol.toLowerCase()}.png`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/coinImg/sample.png";
                      }}
                      alt=""
                    />
                    {item.coinName}
                    <span style={{ color: "#808A9D" }}> {item.symbol}</span>
                  </Link>
                </td>
                {/* <td>${Number(item.priceUsd).toFixed(4)}</td> */}

                <td>
                  {selectedCurrency.type === "crypto"
                    ? `${
                        (Number(item.priceUsd) / selectedCurrencyRate).toFixed(
                          4
                        ) +
                        " " +
                        selectedCurrency.symbol
                      }`
                    : `${selectedCurrency.currencySymbol}${(
                        Number(item.priceUsd) / selectedCurrencyRate
                      ).toFixed(4)}`}
                </td>
                <td
                  className={
                    Number(item.changePercent24Hr) > 0
                      ? "text-success fw-bold"
                      : "text-danger fw-bold"
                  }
                >
                  <i
                    className={
                      item.changePercent24Hr > 0
                        ? "fas fa-caret-up text-success"
                        : "fas fa-caret-down text-danger"
                    }
                    style={{
                      width: "12px",
                      verticalAlign: "middle",
                    }}
                  ></i>
                  &nbsp;
                  {Number(item.changePercent24Hr).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-data">Nothing to see for now!</div>
      )}
    </div>
  );
};

export const TradingData = () => {
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [topGainers, setTopGainers] = useState([]);
  const [topLoosers, setTopLoosers] = useState([]);
  const [topTraded, setTopTraded] = useState([]);
  const [lowVolume, setLowVolume] = useState([]);
  const [trending, setTrending] = useState([]);

  useEffect(() => {
    getAllTrading_Data();
  }, []);

  const getAllTrading_Data = async () => {
    const api1 = await getRecentlyAddedToken();
    const api2 = await getTopGainers();
    const api3 = await getTopLoosers();
    const api4 = await getTopTraded();
    const api5 = await getLowVolume();
    const api6 = await getTrending();

    setRecentlyAdded(api1.data);
    setTopGainers(api2.data);
    setTopLoosers(api3.data);
    setTopTraded(api4.data);
    setLowVolume(api5.data);
    setTrending(api6.data);
  };

  return (
    <div className="card-inner p-3">
      <h3 className="fw-bold fs-3">Trading Data</h3>
      <Row className="pb-5 pt-3">
        <Col className="trading-col">
          <h6>Top Traded</h6>
          <TradingTable data={topTraded} />
        </Col>

        <Col className="trading-col">
          <h6>Top Gainers</h6>
          <TradingTable data={topGainers} />
        </Col>
        <Col className="trading-col">
          <h6>Top Losers</h6>
          <TradingTable data={topLoosers} />
        </Col>
      </Row>
      <Row>
        <Col className="trading-col">
          <h6>Low Volume</h6>
          <TradingTable data={lowVolume} />
        </Col>
        <Col className="trading-col">
          <h6>Trending</h6>
          <TradingTable data={trending} />
        </Col>
        <Col className="trading-col">
          <h6>Recently Added</h6>
          <TradingTable data={recentlyAdded} />
        </Col>
      </Row>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJs,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJs.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const SparklineData = ({ coinId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [options] = useState({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });
  const fetchChartData = async () => {
    setIsLoading(true);

    const response =
      coinId === "qudex"
        ? await axios.get("https://coincurd.com/api/getQudex")
        : await axios.get(
            `https://api.coincap.io/v2/assets/${coinId.toLowerCase()}/history?interval=m15`
          );

    const data = response.data.data;

    const chartData = {
      labels: data.map((d) => {
        const date = new Date(d.date);
        return `${date.toLocaleString("en-IN", {
          day: "2-digit",
          month: "short",
          year: "2-digit",
        })}`;
      }),
      datasets: [
        {
          data: data.map((d) => d.priceUsd),
          borderColor: getLineColor(
            data[0].priceUsd,
            data[data.length - 1].priceUsd
          ),
          tension: 0.5,
          lineTension: 0.4,
          borderWidth: 1.5,
          pointRadius: false,
          pointHitRadius: false,
        },
      ],
    };
    setChartData(chartData);
    setIsLoading(false);
  };

  const getLineColor = (startPrice, endPrice) => {
    if (startPrice < endPrice) {
      return "#198754";
    } else {
      return "#EA3943";
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [coinId]);

  return (
    <div>
      {isLoading ? (
        <div className="">
          <span className="visually-hidden"></span>
        </div>
      ) : (
        <div
          style={{
            height: "45px",
            width: "110px",
            padding: "0px",
            margin: "0px",
            fontWeight: "bold",
            alignItems: "left",
          }}
        >
          <Line data={chartData} options={options} />
        </div>
      )}
    </div>
  );
};

export default SparklineData;

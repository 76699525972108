import React, { createContext, useContext, useState } from "react";

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem("selectedCurrency")
      ? JSON.parse(localStorage.getItem("selectedCurrency"))
      : []
  );

  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState(1);

  const handleSelectedCurrencyChange = (newCurrency) => {
    setSelectedCurrency(newCurrency);
    localStorage.setItem("selectedCurrency", JSON.stringify(newCurrency));
  };

  return (
    <CurrencyContext.Provider
      value={{
        selectedCurrency,
        setSelectedCurrency: handleSelectedCurrencyChange,
        selectedCurrencyRate,
        setSelectedCurrencyRate,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => useContext(CurrencyContext);

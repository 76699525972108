import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

const { allCurrencies, currenciesRate } = require("../apiHandler");

function Converter() {
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState(1);
  const [rateValues, setRateValues] = useState("");
  const [currency, setCurrency] = useState(0);
  const [rateData, setRateData] = useState([]);
  const [currencyDetails, setCurrencyDetails] = useState("");
  const [selectedRate, setSelectedRate] = useState(1);

  const apiData = async () => {
    try {
      const response1 = await allCurrencies();
      const response2 = await currenciesRate();

      let result1 = response1.data;
      let result2 = response2.data;

      setData(result1);
      setRateData(result2);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    apiData();
  }, []);

  useEffect(() => {
    // Set default values for currency and selectedRate once data is loaded
    if (data.length > 0 && rateData.length > 0) {
      const defaultCurrency = data.find(
        (currency) => currency.symbol === "BTC"
      );
      const defaultRate = rateData.find((rate) => rate.symbol === "USD");

      if (defaultCurrency) {
        setCurrencyDetails(defaultCurrency);
        setCurrency(defaultCurrency.priceUsd);
      }

      if (defaultRate) {
        setRateValues(defaultRate);
        setSelectedRate(defaultRate.rateUsd);
      }
    }
  }, [data, rateData]);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleRateChange = (e) => {
    const findRate = rateData.find((val) => val.id === e.target.value);

    setRateValues(findRate);
    setSelectedRate(findRate.rateUsd);
  };

  const handleCurrencyChange = (e) => {
    const findCurrency = data.find((val) => val.symbol === e.target.value);

    setCurrencyDetails(findCurrency);
    setCurrency(findCurrency.priceUsd);
  };

  const convertedAmount = (amount * currency) / selectedRate;

  return (
    <div className="container mt-5">
      <h4 className="text-center">Cryptocurrency Converter Calculator</h4>
      <div
        className="row justify-content-center"
        style={{ backgroundColor: "rgb(248, 248, 248)" }}
      >
        <div className="col-md-4 mb-4 mt-3">
          <input
            type="number"
            placeholder="Enter Amount to Convert"
            className="form-control"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="dropdown">
            <select
              className="form-control"
              onChange={(e) => handleCurrencyChange(e)}
            >
              {data.map((currency) => (
                <option key={currency._id} value={currency.symbol}>
                  {currency.coinName}&nbsp;({currency.symbol})
                </option>
              ))}
            </select>
          </div>
          <div className="btn bg-primary d-flex align-items-center p-3 pt-2 pb-2 ms-3">
            <FontAwesomeIcon icon={faExchangeAlt} />
          </div>
          <div className="dropdown ms-3">
            <select
              className="form-control"
              onChange={(e) => handleRateChange(e)}
            >
              {rateData.map((currency) => (
                <option key={currency._id} value={currency.id}>
                  {currency.id}&nbsp;({currency.symbol})
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="text-center pt-5 pb-5">
          <span className="p-2 fs-6">
            {amount}&nbsp;{currencyDetails.coinName}&nbsp; (
            {currencyDetails.symbol})
          </span>
          <span className="fs-5"> = </span>
          <span className="p-2" style={{ fontSize: "14px" }}>
            <b> {convertedAmount.toFixed(6)}</b>
            <span className="ps-2" style={{ fontSize: "16px" }}>
              {rateValues.id}&nbsp;({rateValues.symbol})
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Converter;

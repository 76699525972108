import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import topLoosers from "../../assets/images/topLoosers.png";

import "./cardBody.css"
const { getTopLoosers } = require("../apiHandler");

const TopLoosers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  async function fetchTopLoosers() {
    try {
      const response = await getTopLoosers();
      const top3Loosers = response.data.slice(0, 3);

      
      setData(top3Loosers);
      setError(null);
    } catch (error) {
      setError("Waiting for server response!");
    }
  }

  useEffect(() => {
    fetchTopLoosers();
  }, []);

  const handleRowClick = (coinId) => {
    navigate(`/currencies/${coinId}`);
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card rounded-8">
            <div className="card-body rounded-8">
              {error ? (
                <p className="text-primary text-center">{error}</p>
              ) : data.length === 0 ? (
                <p>Loading...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <thead>
                      <tr className="fw-bold">
                        <th className="pb-4">
                          <img
                            src={topLoosers}
                            style={{ width: "15px", height: "25px" }}
                            alt=""
                          />
                        </th>
                        <th className="pb-4">Top Loosers</th>
                        <th className="text-end pb-4">
                          <Link
                            to="/trading_data/rankings"
                            className="text-primary"
                          >
                            more <i className="text-link fa fa-arrow-right"></i>
                          </Link>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(item.coinId)}
                          className="table-hover"
                        >
                          <td className="pb-3 fw-bold">{index + 1}</td>
                          <td>
                            <img
                              style={{ width: "15px" }}
                              src={`/coinImg/${item.symbol.toLowerCase()}.png`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/coinImg/sample.png";
                              }}
                              alt=""
                            />
                            <span
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                            >
                              {item.coinName}&nbsp;
                            </span>
                            <span style={{ color: "#808A9D" }}>
                              {item.symbol}
                            </span>
                          </td>
                          <td
                            className={
                              Number(item.changePercent24Hr) > 0
                                ? "text-success fw-bold text-end"
                                : "text-danger fw-bold text-end"
                            }
                          >
                            <i
                              className={
                                item.changePercent24Hr > 0
                                  ? "fas fa-caret-up text-success"
                                  : "fas fa-caret-down text-danger"
                              }
                              style={{
                                width: "12px",
                                verticalAlign: "middle",
                              }}
                            ></i>
                            &nbsp;
                            {Number(item.changePercent24Hr).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopLoosers;

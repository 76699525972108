// apiHandler.js
import axios from "axios";

const API_BASE = "https://coincurd.com";

const apiCache = {};

async function callAPI(apiEndpoint) {
  const cacheKey = API_BASE + apiEndpoint;

  if (apiCache[cacheKey]) {
    return apiCache[cacheKey];
  }

  try {
    const response = await axios.get(API_BASE + apiEndpoint);
    apiCache[cacheKey] = response.data;
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getRecentlyAddedToken() {
  const apiEndpoint = "/api/recentlyAddedToken";
  return callAPI(apiEndpoint);
}

export async function getTopLoosers() {
  const apiEndpoint = "/api/topLoosers";
  return callAPI(apiEndpoint);
}

export async function getTopGainers() {
  const apiEndpoint = "/api/topGainers";
  return callAPI(apiEndpoint);
}

export async function getTopTraded() {
  const apiEndpoint = "/api/topTraded";
  return callAPI(apiEndpoint);
}

export async function getLowVolume() {
  const apiEndpoint = "/api/lowVolume";
  return callAPI(apiEndpoint);
}

export async function getTrending() {
  const apiEndpoint = "/api/trending";
  return callAPI(apiEndpoint);
}

export async function allCurrencies() {
  const apiEndpoint = "/api/getRecentCoins";
  return callAPI(apiEndpoint);
}

export async function currenciesRate() {
  const apiEndpoint = "/api/rates";
  return callAPI(apiEndpoint);
}

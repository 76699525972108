import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";

import MonthChange from "./changePercentage/monthChange";
import YearChange from "./changePercentage/yearChange";
import WeekChange from "./changePercentage/weekChange";
import SparklineData from "./sparkline";

export default function WatchList() {
  const [searchQuery, setSearchQuery] = useState("");

  const [watchlist, setWatchlist] = useState(
    localStorage.getItem("watchlist")
      ? JSON.parse(localStorage.getItem("watchlist"))
      : []
  );

  const navigate = useNavigate();

  const handleCoinNameClick = (coinId) => {
    navigate(`/currencies/${coinId}`);
  };

  const tableColumns = [
    {
      name: <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;#</span>,
      selector: (row) => (
        <div>
          <button
            onClick={() => removeRowFromWatchlist(row)}
            style={{
              color: getButtonColor(row),
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            {getButtonLabel(row)}
          </button>
          &nbsp;
          {row.rank}
        </div>
      ),
      sortable: false,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "80px",
    },

    {
      name: "Name",
      selector: (row) => (
        <div
          onClick={() => handleCoinNameClick(row.coinId)}
          style={{ cursor: "pointer" }}
        >
          <img
            style={{ width: "20px" }}
            src={`../././coinImg/${row.symbol.toLowerCase()}.png`}
            alt=""
          />
          &nbsp;&nbsp;
          {row.coinName}
          &nbsp;&nbsp;
          <span style={{ color: "#808A9D" }}> {row.symbol}</span>
        </div>
      ),

      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "11%",
    },

    {
      name: "Price",
      selector: (row) => Number(row.priceUsd).toFixed(4),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
    },

    {
      name: "1D%",
      selector: (row) => row.changePercent24Hr,
      cell: (row) => {
        const value = row.changePercent24Hr;
        let color = "#198754";
        let icon = <i className="fas fa-caret-up text-success"></i>;

        if (value < 0) {
          color = "#EA3943";
          icon = <i className="fas fa-caret-down text-danger"></i>;
        }

        return (
          <div
            style={{
              color,
              fontWeight: "bold",
              fontSize: "10px",
            }}
          >
            {icon}&nbsp;
            {Number(value).toFixed(2)}%
          </div>
        );
      },
      width: "9%",
      center: true,
    },

    {
      name: "1W%",
      cell: (row) =>
        <WeekChange coinId={row.id} /> ? (
          <WeekChange coinId={row.coinId} />
        ) : (
          ""
        ),
      sortable: false,
      center: true,
      width: "9%",
    },

    {
      name: "1M%",
      cell: (row) =>
        <MonthChange coinId={row.id} /> ? (
          <MonthChange coinId={row.coinId} />
        ) : (
          ""
        ),
      sortable: false,
      center: true,
      width: "9%",
    },

    {
      name: "1Y%",
      cell: (row) =>
        <YearChange coinId={row.coinId} /> ? (
          <YearChange coinId={row.coinId} />
        ) : (
          ""
        ),
      sortable: false,
      center: true,
      width: "9%",
    },
    {
      name: "Volume",
      selector: (row) => Number(row.volumeUsd24Hr).toFixed(2),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "9%",
    },
    {
      name: "Supply",
      selector: (row) => Number(row.supply).toFixed(2),
      sortable: true,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "10%",
    },

    {
      name: "MarketCap",
      selector: (row) => Number(row.marketCapUsd).toFixed(2),
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "9%",
    },

    {
      name: "7days",

      cell: (row) =>
        <SparklineData coinId={row.coinId} /> ? (
          <SparklineData coinId={row.coinId} />
        ) : (
          ""
        ),
      sortable: false,
      center: true,
      width: "11%",
    },
  ];

  const removeRowFromWatchlist = (row) => {
    const updatedWatchlist = watchlist.filter(
      (coin) => coin.coinId !== row.coinId
    );
    localStorage.setItem("watchlist", JSON.stringify(updatedWatchlist));
    setWatchlist(updatedWatchlist);
  };

  let isWatched = (item) => {
    return watchlist.some((watchlist) => watchlist.coinId === item.coinId);
  };

  const getButtonLabel = (coin) => {
    return isWatched(coin) ? (
      <FontAwesomeIcon icon={solidStar} style={{ width: "9px" }} />
    ) : (
      <FontAwesomeIcon icon={regularStar} style={{ width: "9px" }} />
    );
  };

  const getButtonColor = (coin) => {
    return isWatched(coin) ? "#ff8000" : "#000";
  };

  const handleRowClick = (row) => {
    navigate(`/currencies/${row.coinId}`);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let filteredCoins = [];

  if (watchlist) {
    filteredCoins = watchlist.filter(
      (coin) =>
        coin.coinName &&
        coin.symbol &&
        (coin.coinName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          coin.symbol.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }

  filteredCoins.sort((a, b) => {
    return parseInt(a.rank) - parseInt(b.rank);
  });

  return (
    <div className="ps-2">
      <div className="mt-3 mb-3 text-start ms-2">
        <h3>Track Your Watchlist</h3>
        <h6>Sign up to save for longer</h6>
      </div>

      <div class="topnav">
        <div className="search-container">
          <input
            maxLength="200"
            spellCheck="false"
            className="form-control search-bar"
            type="text"
            placeholder="Search coin, symbol"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>
      </div>

      <div className="datatable-container">
        <DataTable
          data={filteredCoins}
          columns={tableColumns}
          striped={true}
          pagination={true}
          paginationPerPage={30}
          onRowClicked={handleRowClick}
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import "./subHeader.css";
import logoImg from "../../assets/images/coincurd.png";

const API_BASE = "https://coincurd.com";

function SubHeader() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchInputChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query === "") {
      setSearchResults([]);
      return;
    }

    try {
      const response = await axios.get(API_BASE + `/api/search/coin`, {
        params: {
          query: query,
        },
      });

      const { data } = response.data;
      setSearchResults(data.slice(0, 5));
    } catch (error) {
      console.error("Waiting for server response!");
    }
  };

  return (
    <nav className="navbar navbar-expand-sm ps-1">
      <div className="container-fluid ps-0">
        <a className="d-lg-none" href="/">
          <img style={{ width: "95px" }} src={logoImg} alt="coinCurd" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="mynavbar">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Cryptocurrencies
              </a>
            </li>
            <li className="nav-item">
              <a href="/rankings/exchanges" className="nav-link">
                Exchanges
              </a>
            </li>

            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products
              </span>
              <ul className="dropdown-menu bg-light text-dark">
                <li style={{ fontSize: "11px" }}>
                  <Link className="dropdown-item fw-bold" to="/converter">
                    Converter
                  </Link>
                </li>
                <li style={{ fontSize: "11px" }}>
                  <Link className="dropdown-item fw-bold" to="/widget">
                    Widget
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <form className="d-flex">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a href="/watchlist" className="nav-link">
                  <i className="fa fa-star-o"></i> &nbsp;Favourites
                </a>
              </li>
            </ul>

            <div className="search-container ms-4">
              <input
                maxLength="200"
                spellCheck="false"
                className="form-control"
                type="text"
                placeholder="Search coin, symbol"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              {searchResults.length > 0 && (
                <div className="search-results-dropdown dropdown-menu show">
                  {searchResults.map((coin) => (
                    <a
                      key={coin.coinId}
                      href={`/currencies/${coin.coinId}/`}
                      className="dropdown-item"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            style={{ width: "10%" }}
                            src={`/coinImg/${coin.symbol.toLowerCase()}.png`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/coinImg/sample.png";
                            }}
                            alt=""
                            className="me-2"
                          />
                          <span className="me-2 fw-bold">{coin.coinName}</span>
                          <span style={{ fontSize: "12px", color: "#58667E" }}>
                            {coin.symbol}
                          </span>
                        </div>
                        <div className="text-end">
                          <span style={{ fontSize: "12px", color: "#58667E" }}>
                            #{coin.rank}
                          </span>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default SubHeader;

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Row, Col } from "reactstrap";
import axios from "axios";
import banner4 from "../../assets/images/Up Banner ban-01.jpg";
import banner5 from "../../assets/images/Up Banner ban-02.jpg";
import { useNavigate } from "react-router-dom";

const API_BASE = "https://coincurd.com";

const Exchange = () => {
  const [ex, setEx] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFullParagraph, setShowFullParagraph] = useState(false);

  const navigate = useNavigate();

  const fetchExchanges = async () => {
    try {
      const response = await axios.get(API_BASE + `/api/recentEx`);
      const result = response.data.data;
      if (result) {
        setEx(result);
      }
      setLoading(false);
    } catch (error) {
      setError("Waiting for server response!");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExchanges();
  }, []);

  const tableColumns = [
    {
      name: "#",
      selector: (row) => row.rank,
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "70px",
    },
    {
      name: "ExchangeId",
      selector: (row) => row.exchangeId,
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        cursor: "pointer",
      },
      width: "130px",
    },
    {
      name: "ExchangeName",
      selector: (row) => row.exchangeName,
      sortable: true,
      center: false,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        cursor: "pointer",
      },
      width: "170px",
    },
    {
      name: "Volume",
      selector: (row) => Number(row.volumeUsd).toFixed(4),
      sortable: true,
      center: true,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "140px",
    },
    {
      name: "TotalVolume (%)",
      selector: (row) => Number(row.percentTotalVolume).toFixed(4),
      sortable: true,
      center: true,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      width: "150px",
    },
    {
      name: "TradingPairs",
      selector: (row) => row.tradingPairs,
      sortable: true,
      center: true,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/exchanges/${row.exchangeName}`);
  };

  const paragraph =
    "We now track 73 spot exchanges with a total 24h volume of $189.08B. For more info on exchange ranking, click here .";

  const firstSentence =
    "CoinCurd ranks and scores exchanges based on traffic, liquidity, trading volumes, and confidence in the legitimacy of trading volumes reported.";

  const handleToggleParagraph = () => {
    setShowFullParagraph(!showFullParagraph);
  };

  return (
    <div>
      <div className="ps-2 pb-4">
        <h5 className="fw-bold text-start pt-4 ps-1">
          Top Cryptocurrency Exchanges
        </h5>
        <div className="ps-2" style={{ color: "#58667E", fontSize: "14px" }}>
          {firstSentence}
          {!showFullParagraph && (
            <span
              onClick={handleToggleParagraph}
              className="text-primary ps-1"
              style={{ cursor: "pointer" }}
            >
              Read More
            </span>
          )}

          {showFullParagraph && (
            <div>
              {paragraph}
              <span
                onClick={handleToggleParagraph}
                className="text-primary ps-1"
                style={{ cursor: "pointer" }}
              >
                Read Less
              </span>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <p className="text-center text-primary">Loading...</p>
      ) : error ? (
        <p className="text-center text-primary">{error}</p>
      ) : (
        <Row>
          <Col xl="8">
            <div className="datatable-container">
              {/* <p className="fw-bold text-start bg-white p-2 fs-5 ps-3">
                All Exchanges
              </p> */}
              <DataTable
                data={ex}
                columns={tableColumns}
                pagination={true}
                paginationPerPage={25}
                onRowClicked={handleRowClick}
              />
            </div>
          </Col>

          <Col xl="4" className="p-3 pt-4 d-none d-xl-block">
            <div className="text-start pt-3">
              <img
                src={banner4}
                style={{
                  width: "100%",
                  height: "600px",
                  borderRadius: "8px",
                }}
                alt="Banner 4"
              />
            </div>
            <div className="text-start mt-4 pt-4">
              <img
                src={banner5}
                style={{
                  width: "100%",
                  height: "600px",
                  borderRadius: "8px",
                }}
                alt="Banner 5"
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default Exchange;

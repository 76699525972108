import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/coincurd.png";
import "./footer.css";

const Footer = () => {
  return (
    <div className="ps-2 footer">
      <Row className="p-2 pb-0">
        <Col className="pb-3">
          <div className="topbar">
            <h6>About</h6>
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://guide.coincurd.com/overview/about-us"
                  target="_blank"
                  rel="noreferrer"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                  href="https://guide.coincurd.com/overview/ad-policy"
                >
                  Ad Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                  href="https://guide.coincurd.com/overview/disclaimer"
                >
                  Disclaimer
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                  href="https://guide.coincurd.com/overview/methodology"
                >
                  Methodology
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                  href="https://guide.coincurd.com/overview/privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                  href="https://guide.coincurd.com/overview/term-and-conditions"
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
        </Col>
        <Col>
          <h6>Resources</h6>
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://guide.coincurd.com/overview/api"
              >
                API
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://guide.coincurd.com/overview/news"
              >
                News
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://guide.coincurd.com/overview/sitemap"
              >
                Sitemap
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://guide.coincurd.com/overview/donation"
              >
                Donations
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://documenter.getpostman.com/view/27887113/2s93sc5Cmb"
              >
                Documentation
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://guide.coincurd.com/overview/blockchain-explorer"
              >
                Blockchain Explorer
              </a>
            </li>
          </ul>
        </Col>
        <Col>
          <h6>Support</h6>
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/faq/"
              >
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/bug-bounty/"
              >
                Bug Bounty
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/request-form/"
              >
                Request Form
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/contact-center/"
              >
                Contact Center
              </a>
            </li>
          </ul>
        </Col>
        <Col>
          <h6>Community</h6>
          <ul className="navbar-nav me-auto social-links-grid ps-4">
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/twitter/"
              >
                <i
                  className="icofont icofont-social-twitter"
                  style={{ fontSize: "20px" }}
                ></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/telegram/"
              >
                <i
                  className="icofont icofont-social-telegram"
                  style={{ fontSize: "20px" }}
                ></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/facebook/"
              >
                <i
                  className="icofont icofont-social-facebook"
                  style={{ fontSize: "20px" }}
                ></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                href="https://coincurd-2ef13f.ingress-baronn.ewp.live/instagram/"
              >
                <i
                  className="icofont icofont-social-instagram"
                  style={{ fontSize: "20px" }}
                ></i>
              </a>
            </li>
          </ul>
        </Col>
        <Col>
          <div>
            <Link to={"/"}>
              <img style={{ width: "120px" }} src={logoImg} alt="coinCurd" />
            </Link>
            <p style={{ fontSize: "11px" }}>
              Coincurd is a cryptocurrency data website that provides live
              crypto prices.
            </p>
            <div className="text-secondary">
              <span>© Coincurd All rights reserved!</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;

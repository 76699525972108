import React, { useState, useEffect } from "react";
import axios from "axios";
import "./socialMedia.css";
import "bootstrap";
import { useParams } from "react-router-dom";

const API_BASE = "https://coincurd.com";

const SocialMediaLinks = ({ onUpdateSocialData }) => {
  const [socialData, setSocialData] = useState({});
  const { coinId } = useParams();

  const fetchSocialData = async () => {
    try {
      const response = await axios.get(API_BASE + `/api/links/${coinId}`);
      const result = response.data.data.social || {};
      setSocialData(result);
      onUpdateSocialData(result);
    } catch (error) {
      setSocialData({});
    }
  };

  useEffect(() => {
    fetchSocialData();
  }, [coinId]);

  const renderSocialLinks = () => {
    return Object.entries(socialData).map(([key, value]) => {
      if (value) {
        return (
          <li key={key} className="m-2">
            <img
              style={{ height: "15px", marginRight: "2%" }}
              src={`/socialLogo/${key}.png`}
              alt=""
            />
            <a href={value} target="_blank" rel="noreferrer">
              {value}
            </a>
          </li>
        );
      }
      return null;
    });
  };

  return (
    <div className="dropdown">
      <span style={{ color: "#000000", fontSize: "14px" }}>
        Social Links &nbsp;
        <i
          className="fa fa-caret-down"
          style={{ fontSize: "15px", paddingBottom: "10px" }}
        ></i>
      </span>
      <ul className="dropdown-content search-results-dropdown">
        {renderSocialLinks()}
      </ul>
    </div>
  );
};

export default SocialMediaLinks;

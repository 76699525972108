import Loader from "./Loader";
import Header from "./Header";
import Footer from "./Footer";
import React from "react";

import { Outlet } from "react-router-dom";

const AppLayout = () => {
  return (
    <div>
      <Loader />
      <div>
        <Header />

        <Outlet />

        <Footer />
      </div>
    </div>
  );
};
export default AppLayout;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import banner4 from "../../assets/images/Up Banner ban-01.jpg";
import banner5 from "../../assets/images/Up Banner ban-02.jpg";
import ChartApi from "./coinChart";
import CryptoConverter from "../product/converter";
import SocialMediaLinks from "../socialLinks/socialMediaLinks";
import ProgressBar from "../progressBar/progressBar";
import MarketTables from "./coinMarket";

import SupplyProgressBar from "../progressBar/SupplyProgressBar";

import { useCurrency } from "../context/CurrencyContext";

const API_BASE = "https://coincurd.com";

const CoinChartMarket = () => {
  const { coinId } = useParams();

  const [data, setData] = useState({});
  const [socialData, setSocialData] = useState({});

  const { selectedCurrency, selectedCurrencyRate } = useCurrency();

  useEffect(() => {
    const fetchCoinData = async () => {
      try {
        const response = await axios.get(`${API_BASE}/api/getCoin/${coinId}`);
        const result = response.data.data;

        if (result) {
          setData(result);
        }
      } catch (error) {
        console.error("Waiting for server response!");
      }
    };
    fetchCoinData();
  }, [coinId]);

  const updateSocialData = (data) => {
    setSocialData(data);
  };

  const coinImg =
    typeof data.symbol === "string" ? data.symbol.toLowerCase() : "";

  const fullyDilutedMarketCap = Number(data.maxSupply)
    ? Number(data.priceUsd) * Number(data.maxSupply)
    : "--";

  const socialLogos = {
    Explorer: socialData.Explorer,
    Bitcointalk: socialData.Bitcointalk,
    Facebook: socialData.Facebook,
    Explorer4: socialData.Explorer4,
    Explorer3: socialData.Explorer3,
    Explorer2: socialData.Explorer2,
    Explorer1: socialData.Explorer1,
    Message_Board: socialData.Message_Board,
    Chat: socialData.Chat,
    Discord: socialData.Discord,
    GitHub: socialData.GitHub,
    Telegram: socialData.Telegram,
    Reddit: socialData.Reddit,
    Twitter: socialData.Twitter,
  };

  // const volumeMarketCapRatio =
  //   Number(data.volumeUsd24Hr) / Number(data.marketCapUsd);
  // const formattedRatio = volumeMarketCapRatio;

  const volumeMarketCapRatio =
    Number(data.marketCapUsd) > 0
      ? data.volumeUsd24Hr / Number(data.marketCapUsd)
      : 0;

  return (
    <div className="dashboard-default-sec">
      <Row className="bg-white border-start border-bottom border-right border-end border-left border-top pb-2">
        <Col className="d-flex" xs={12} md={4}>
          <div className="p-3 d-flex align-items-start" style={{ gap: "16px" }}>
            <div>
              <img
                style={{ height: "75px", width: "70px" }}
                src={`/coinImg/${coinImg}.png`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/coinImg/sample.png";
                }}
                alt=""
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <div className="d-flex align-items-center">
                  <span className="fs-5 fw-bold">{data.coinName}</span>
                  <span className="ps-2 fs-5 fw-bold text-secondary">
                    {data.symbol}
                  </span>
                  <div className="ms-5 ps-4">
                    <button
                      className="text-end align-items-end ml-5"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "8px",
                        border: "2px solid #808A9D",
                        height: "1.5rem",
                        fontSize: "14px",
                      }}
                    >
                      <a href="/watchList">
                        <i
                          className="fa fa-star-o"
                          style={{ color: "#808A9D" }}
                        ></i>
                      </a>
                    </button>
                  </div>
                </div>
                <div className="text-start">
                  <span className="fs-6 fw-bold">Rank: #{data.rank}</span>
                  <span className="ms-2">
                    <SocialMediaLinks onUpdateSocialData={updateSocialData} />
                  </span>
                </div>
              </div>
              <div className="text-start">
                <ul
                  className="social-logos"
                  style={{ display: "flex", gap: "10px" }}
                >
                  {Object.entries(socialLogos).map(([key, link], index) => {
                    if (link) {
                      return (
                        <li key={index}>
                          <img
                            style={{ height: "20px" }}
                            src={`/socialLogo/${key}.png`}
                            alt={key}
                            onClick={() => {
                              window.open(link, "_blank");
                            }}
                          />
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          md={4}
          className="border-start"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="p-3 text-start" style={{ textAlign: "left" }}>
            <div>
              <span className="fs-5 fw-bold">
                {data.coinName} Price (
                <span className="text-secondary fw-bold">{data.symbol}</span>)
              </span>
            </div>
            <div>
              {/* <span className="fs-6 fw-bold text-secondary">
                ${Number(data.priceUsd).toFixed(4)}
              </span> */}

              <span className="fs-6 fw-bold text-secondary">
                {selectedCurrency.type === "crypto"
                  ? `${
                      (Number(data.priceUsd) / selectedCurrencyRate).toFixed(
                        4
                      ) +
                      " " +
                      selectedCurrency.symbol
                    }`
                  : `${selectedCurrency.currencySymbol}${(
                      Number(data.priceUsd) / selectedCurrencyRate
                    ).toFixed(4)}`}
              </span>
            </div>
          </div>
        </Col>
        <Col className="border-start text-center p-0 m-0" xs={12} md={4}>
          <CryptoConverter />
        </Col>
      </Row>
      <Row className="pt-2 mt-3">
        <Col xl="8" md="6">
          <ChartApi />
        </Col>
        <Col>
          <button
            type="button"
            className="btn btn-light"
            style={{
              width: "1%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "10px",
            }}
          >
            24Hr
          </button>
          <div className="pb-3 d-flex align-items-center">
            <span className="m-2 flex-grow-1">
              <ProgressBar />
            </span>
          </div>
          <hr className="text-secondary" />
          <div className="d-flex flex-row justify-content-between text-start mb-3">
            <div className="d-flex flex-column border-end p-2 w-50">
              <span className="fw-bold text-secondary">Market Cap</span>
              {/* <span className="fw-bold">
                ${Number(data.marketCapUsd).toFixed(4)}
              </span> */}

              <span className="fw-bold">
                {selectedCurrency.type === "crypto"
                  ? `${
                      (
                        Number(data.marketCapUsd) / selectedCurrencyRate
                      ).toFixed(4) +
                      " " +
                      selectedCurrency.symbol
                    }`
                  : `${selectedCurrency.currencySymbol}${(
                      Number(data.marketCapUsd) / selectedCurrencyRate
                    ).toFixed(4)}`}
              </span>
              <span className="pt-3">
                <ul className="list-unstyled">
                  <li className="d-flex justify-content-between">
                    <span>24h Vol/MarketCap</span>

                    <span>
                      {selectedCurrency.type === "crypto"
                        ? `${
                            (
                              volumeMarketCapRatio / selectedCurrencyRate
                            ).toFixed(6) +
                            " " +
                            selectedCurrency.symbol
                          }`
                        : `${selectedCurrency.currencySymbol}${(
                            volumeMarketCapRatio / selectedCurrencyRate
                          ).toFixed(6)}`}
                    </span>
                  </li>
                </ul>
              </span>
            </div>
            <div className="d-flex flex-column text-start p-2 w-50">
              <span className="fw-bold text-secondary">
                Fully Diluted Market Cap
              </span>
              {/* <span className="fw-bold">${fullyDilutedMarketCap}</span> */}
              <span className="fw-bold">
                {fullyDilutedMarketCap !== "--"
                  ? selectedCurrency.type === "crypto"
                    ? `${
                        (fullyDilutedMarketCap / selectedCurrencyRate).toFixed(
                          4
                        ) +
                        " " +
                        selectedCurrency.symbol
                      }`
                    : `${selectedCurrency.currencySymbol}${(
                        fullyDilutedMarketCap / selectedCurrencyRate
                      ).toFixed(4)}`
                  : fullyDilutedMarketCap}
              </span>
            </div>
          </div>

          <hr className="text-secondary" />
          <div className="d-flex flex-row justify-content-between text-start ">
            <div className="d-flex flex-column border-end p-2 w-50">
              <span className="fw-bold text-secondary">Volume</span>
              {/* <span className="fw-bold">
                ${Number(data.volumeUsd24Hr).toFixed(4)}
              </span> */}

              <span className="fw-bold">
                {selectedCurrency.type === "crypto"
                  ? `${
                      (
                        Number(data.volumeUsd24Hr) / selectedCurrencyRate
                      ).toFixed(4) +
                      " " +
                      selectedCurrency.symbol
                    }`
                  : `${selectedCurrency.currencySymbol}${(
                      Number(data.volumeUsd24Hr) / selectedCurrencyRate
                    ).toFixed(4)}`}
              </span>
            </div>
            <div className="d-flex flex-column p-2 w-50">
              <span className="fw-bold text-secondary">Circulating Supply</span>
              <span className="fw-bold">
                {data.supply}&nbsp;{data.symbol}
              </span>
              <span>
                <SupplyProgressBar
                  maxSupply={data.maxSupply}
                  supply={data.supply}
                />
              </span>
              <span>
                <ul className="list-unstyled">
                  <li className="d-flex justify-content-between">
                    <span className="text-secondary fw-bold">Max Supply</span>
                    <span>
                      {data.maxSupply}&nbsp;{data.symbol}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span className="text-secondary fw-bold">Total Supply</span>
                    <span>
                      {data.supply}&nbsp;{data.symbol}
                    </span>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <hr className="text-secondary" />
        </Col>
      </Row>

      <Row className="pt-2">
        <Col xl="8">
          <MarketTables />
        </Col>
        <Col xl="4" className="p-3 pt-5 d-none d-xl-block">
          <div className="text-start pt-3">
            <img
              src={banner4}
              style={{ width: "100%", height: "600px", borderRadius: "8px" }}
              alt="Banner 4"
            />
          </div>
          <div className="text-start mt-5 pt-4">
            <img
              src={banner5}
              style={{ width: "100%", height: "600px", borderRadius: "8px" }}
              alt="Banner 5"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CoinChartMarket;

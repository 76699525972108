import React, { useEffect, useState } from "react";
import axios from "axios";

const YearChange = ({ coinId }) => {
  const [yearPercentageChange, setYearPercentageChange] = useState(null);

  const fetchYearChartData = async () => {
    try {
      const response = await axios.get(
        `https://api.coincap.io/v2/assets/${coinId.toLowerCase()}/history?interval=h12`
      );

      const data = response.data.data;
      const firstPrice = parseFloat(data[0].priceUsd);
      const lastPrice = parseFloat(data[data.length - 1].priceUsd);
      const percentageChange = ((lastPrice - firstPrice) / firstPrice) * 100;

      setYearPercentageChange(percentageChange);
    } catch (error) {
      console.error("Waiting for server response!");
    }
  };

  useEffect(() => {
    fetchYearChartData();
  }, [coinId]);

  const percentageColor = yearPercentageChange > 0 ? "#198754" : "#EA3943";
  const iconClassName =
    yearPercentageChange > 0 ? "fas fa-caret-up" : "fas fa-caret-down";

  return (
    <div className="chart-container">
      {yearPercentageChange !== null && (
        <p
          style={{
            color: percentageColor,
            fontWeight: "bold",
            fontSize: "10px",
          }}
        >
          <i className={iconClassName}></i>&nbsp;
          {Number(yearPercentageChange).toFixed(2)}%
        </p>
      )}
    </div>
  );
};

export default YearChange;

import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import LayoutRoutes from "../Route/LayoutRoutes";

const Routers = () => {
  return (
    <BrowserRouter basename={"/"}>
      <>
        <Routes>
          <Route path={`/*`} element={<LayoutRoutes />} />
        </Routes>
      </>
    </BrowserRouter>
  );
};

export default Routers;

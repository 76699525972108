import React, { useState, useEffect } from "react";
import logoImg from "../../assets/images/coincurd.png";
import signImg from "../../assets/images/Vector.png";
import { useNavigate, Link } from "react-router-dom";
import { useCurrency } from "../../Components/context/CurrencyContext";

const { currenciesRate } = require("../../Components/apiHandler");

function TopHeader() {
  const [currency, setCurrency] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { setSelectedCurrency, setSelectedCurrencyRate } = useCurrency();

  const navigate = useNavigate();

  const fetchCurrencyOptions = async () => {
    try {
      const response = await currenciesRate();
      const data = await response.data;
      setCurrencyOptions(data);
    } catch (error) {
      console.error("Error fetching currency options:", error);
    }
  };

  useEffect(() => {
    fetchCurrencyOptions();
  }, []);

  useEffect(() => {
    if (currencyOptions.length > 0) {
      const storedCurrencySymbol = localStorage.getItem(
        "selectedCurrencySymbol"
      );
      const storedCurrency = currencyOptions.find(
        (currency) => currency.symbol === storedCurrencySymbol
      );

      setCurrency(storedCurrency || currencyOptions[0]);
      setSelectedCurrencyRate(
        storedCurrency ? storedCurrency.rateUsd : currencyOptions[0].rateUsd
      );
      setSelectedCurrency(storedCurrency || currencyOptions[0]);
    }
  }, [currencyOptions]);

  const handleCurrencyChange = (e) => {
    const selectedCurrencyInfo = currencyOptions.find(
      (val) => val.symbol === e.symbol
    );

    localStorage.setItem("selectedCurrencySymbol", selectedCurrencyInfo.symbol);

    setCurrency(selectedCurrencyInfo);
    setSelectedCurrencyRate(selectedCurrencyInfo.rateUsd);
    setSelectedCurrency(selectedCurrencyInfo);
    setDropdownVisible(true);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const scrollToSection = () => {
    const options = {
      duration: 500,
      offset: -50,
      smooth: "easeInOutQuad",
    };

    const targetElement = document.getElementById("targetSection");
    if (targetElement) {
      targetElement.scrollIntoView(options);
    } else {
      navigate("/");
    }
  };

  const fiatCurrencies = currencyOptions.filter(
    (currency) => currency.type === "fiat"
  );
  const cryptoCurrencies = currencyOptions.filter(
    (currency) => currency.type === "crypto"
  );

  const renderCurrencies = (currencies) => {
    const rows = [];
    for (let i = 0; i < currencies.length; i += 4) {
      const rowCurrencies = currencies.slice(i, i + 4);
      rows.push(
        <div className="row" key={i}>
          {rowCurrencies.map((currency) => (
            <div className="col" key={currency._id}>
              <span
                className="dropdown-item"
                onClick={() => handleCurrencyChange(currency)}
              >
                <ul>
                  <li>{currency.id}</li>
                  <li className="text-secondary">
                    {currency.symbol}
                    {currency.currencySymbol
                      ? `-${currency.currencySymbol}`
                      : ""}
                  </li>
                </ul>
              </span>
            </div>
          ))}
        </div>
      );
    }
    return rows;
  };

  return (
    <div className="border-bottom ps-0 ms-0">
      <nav className="navbar navbar-expand-sm p-0 m-0">
        <div className="container-fluid ps-0">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  <img style={{ width: "95px" }} src={logoImg} alt="coinCurd" />
                </Link>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Markets
                </span>
                <ul className="dropdown-menu bg-light text-dark">
                  <li
                    style={{ fontSize: "11px", cursor: "pointer" }}
                    className="fw-bold "
                  >
                    <span
                      className="dropdown-item fw-bold"
                      onClick={scrollToSection}
                    >
                      Market Overview
                    </span>
                  </li>
                  <li style={{ fontSize: "11px" }}>
                    <Link
                      className="dropdown-item fw-bold"
                      to="/trading_data/rankings"
                    >
                      Trading Data
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Feed
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Historical Data
                </Link>
              </li>
            </ul>
            <form className="d-flex">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link">
                    English&nbsp;
                    <i className="fa fa-caret-down"></i>
                  </span>
                </li>

                <li className="nav-item dropdown position-static">
                  <div
                    className="nav-link dropdown-toggle"
                    role="button"
                    onMouseEnter={toggleDropdown}
                  >
                    {currency.symbol}
                  </div>
                  <div
                    className={`dropdown-menu bg-light${
                      dropdownVisible ? " show" : ""
                    }`}
                    id="currency-dropdown"
                    style={{
                      width: "80%",
                      height: "80vh",
                      overflowY: "auto",
                      position: "absolute",
                      top: "calc(100% + 10px)",
                      right: 0,
                      zIndex: 10000,
                      fontSize: "12px",
                    }}
                    onMouseLeave={closeDropdown}
                  >
                    <div className="container-fluid p-3">
                      <h6>Crypto</h6>
                      {renderCurrencies(cryptoCurrencies)}
                    </div>
                    <div className="dropdown-divider"></div>
                    <div className="container-fluid p-3">
                      <h6>Fiat</h6>
                      {renderCurrencies(fiatCurrencies)}
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <Link
                    to="https://user.coincurd.com/auth-login"
                    className="nav-link"
                  >
                    Sign In
                    <img
                      style={{ width: "12px", height: "12px" }}
                      src={signImg}
                      alt="sign"
                    />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="p-2 m-0 btn btn-sm"
                    style={{
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#54AFEB",
                    }}
                  >
                    BUY CCT
                  </Link>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default TopHeader;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const API_BASE = "https://coincurd.com";

export default function CryptoConverter() {
  const [coinPrice, setCoinPrice] = useState(0);
  const [coinAmount, setCoinAmount] = useState(1);
  const [converted, setConverted] = useState(0);
  const [symbol, setSymbol] = useState("");

  const { coinId } = useParams();

  const currencyPrice = async () => {
    try {
      let response = await axios({
        method: "get",
        url: API_BASE + `/api/convert/${coinId}`,
      });

      let result = response.data.data.priceUsd;
      let symbol = response.data.data.symbol;

      if (result) {
        setCoinPrice(result);
        setSymbol(symbol);
        setConverted(result);
      }
    } catch (error) {
      return <div>"Waiting for server response!"</div>;
    }
  };

  function handleCoinAmountChange(event) {
    const value = event.target.value;
    if (value >= 0) {
      setCoinAmount(value);
      setConverted(value * coinPrice);
    } else {
      setCoinAmount(0);
      setConverted(0);
    }
  }

  useEffect(() => {
    currencyPrice();
  }, []);

  return (
    <>
      <div className="container text-center mt-1">
        <span className="fs-6 fw-bold">{symbol} to USD Converter</span>
        <div className="row">
          <div className="col display flex">
            <input value={symbol} className="form-control" readOnly />
          </div>

          <div className="col">
            <input
              type="number"
              className="form-control"
              placeholder="Enter amount"
              value={coinAmount}
              onChange={handleCoinAmountChange}
            />
          </div>
        </div>
        <br />

        {coinPrice && coinPrice > 0 && (
          <button
            style={{
              backgroundImage:
                "linear-gradient( to right, #042866, #1B63DF, #042661 )",
              border: "none",
              borderRadius: "8px",
              color: "white",
              width: "60%",
              height: "2rem",
            }}
          >
            {coinAmount} {symbol.toUpperCase()} = <span>&#36;</span>
            {converted && Number(converted).toFixed(4)}
          </button>
        )}
      </div>
    </>
  );
}

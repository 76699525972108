import React from "react";
import TopHeader from "./TopHeader";

import SubNavbar from "./subHeader";

const Header = () => {
  return (
    <div style={{ backgroundColor: "#FFFFFF" }} className="ps-1">
      <TopHeader />
      <SubNavbar />
    </div>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Chart from "react-apexcharts";

const API_BASE = "https://coincurd.com";

export default function ChartApi() {
  const [isLoading, setIsLoading] = useState(true);
  const { coinId } = useParams();
  const [view, setView] = useState("view1");
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  const calculateGradientColors = (dataPrices) => {
    if (!Array.isArray(dataPrices) || dataPrices.length === 0) {
      return [];
    }

    let previousPrice = null;
    let gradientColors = [];

    dataPrices.forEach((currentPrice) => {
      let gradientColorFrom, gradientColorTo;

      if (previousPrice === null || currentPrice >= previousPrice) {
        gradientColorFrom = "#e5ffe5"; // Light Green
        gradientColorTo = "#9aff9a"; // Green
      } else {
        gradientColorFrom = "#fff0f0"; // Light Red
        gradientColorTo = "#ff9a9a"; // Red
      }

      previousPrice = currentPrice;
      gradientColors.push({ from: gradientColorFrom, to: gradientColorTo });
    });

    if (gradientColors.length > 1) {
      gradientColors[gradientColors.length - 1].to =
        gradientColors[gradientColors.length - 2].to;
    }

    return gradientColors;
  };

  const calculateDataPrices = (result) => {
    let dataPrices = [];

    result.forEach((data) => {
      dataPrices.push(
        view === "view3" || view === "view4"
          ? data.priceUsd
          : data.price || data.priceUsd
      );
    });

    return dataPrices;
  };

  useEffect(() => {
    const fetchChartData = async () => {
      let apiUrl;

      switch (view) {
        case "view1":
          apiUrl = `${API_BASE}/api/oneHrChart/${coinId}`;
          break;
        case "view2":
          apiUrl =
            coinId === "qudex"
              ? `${API_BASE}/api/oneMonthLimit`
              : `${API_BASE}/api/oneDayChart/${coinId}`;
          break;
        case "view3":
          apiUrl =
            coinId === "qudex"
              ? `${API_BASE}/api/oneMonthLimit`
              : coinId !== "qudex"
              ? `https://api.coincap.io/v2/assets/${coinId.toLowerCase()}/history?interval=h1`
              : `${API_BASE}/api/oneDayChart/${coinId}`;
          break;
        case "view4":
          apiUrl =
            coinId === "qudex"
              ? `${API_BASE}/api/getQudex`
              : coinId !== "qudex"
              ? `https://api.coincap.io/v2/assets/${coinId.toLowerCase()}/history?interval=d1`
              : `${API_BASE}/api/oneDayChart/${coinId}`;
          break;
        default:
          apiUrl = "";
      }

      try {
        const response = await axios.get(apiUrl);
        const result = response.data.data;

        let dataLabels = result.map((data) => formatDate(data.date));
        let dataPrices = calculateDataPrices(result);

        setChartOptions({
          chart: {
            type: "line",
            stacked: false,
            height: 450,
            width: "100%",
            animations: {
              initialAnimation: {
                enabled: true,
              },
            },
            zoom: {
              enabled: true,
            },
            background: "#f5f7f7",
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
            },
            grid: {
              display: true,
            },
          },
          yaxis: {
            opposite: true,
            labels: {
              formatter: (val) => "$" + val.toFixed(4),
            },
            tooltip: {
              enabled: true,
            },
            grid: {
              display: false,
            },
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0.5,
              opacityFrom: 0.4,
              opacityTo: 0.2,
              inverseColors: true,
              colorStops: calculateGradientColors(dataPrices).map(
                (colors, index) => ({
                  offset: (index / (dataPrices.length - 1)) * 100,
                  color: colors.from,
                })
              ),
            },
          },
          dataLabels: {
            enabled: false,
          },
        });

        setChartSeries([
          {
            name: "Price",
            data: dataPrices.map((price, index) => [dataLabels[index], price]),
          },
        ]);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchChartData();
  }, [view, coinId]);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.getTime();
  };

  const renderView = () => {
    const views = ["view1", "view2", "view3", "view4"];
    if (!views.includes(view)) {
      return <div>This is the default view</div>;
    }

    return (
      <div className="chart-container-graph" style={{ background: "#f5f7f7" }}>
        {isLoading ? (
          <div className="spinner-border text-primary">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <div className="chart-container-graph">
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="area"
              height={350}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="mb-2">
        <div className="ml-auto text-end">
          {["view1", "view2", "view3", "view4"].map((v) => (
            <button
              key={v}
              className={`btn ${
                view === v ? "btn-outline-dark" : "btn-light"
              } btn-sm`}
              style={{ padding: "8px", minWidth: "auto" }}
              onClick={() => setView(v)}
            >
              {v === "view1"
                ? "1Hr"
                : v === "view2"
                ? "1D"
                : v === "view3"
                ? "1M"
                : "1Y"}
            </button>
          ))}
        </div>
      </div>

      {renderView()}
    </>
  );
}
